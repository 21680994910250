import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { CartContext, useShopStoreIncontext } from '../../context/shopContext'
import { Helmet } from 'react-helmet'

import banner from '../../images/Banner.jpg'
import banner2 from '../../images/banner-e.png'

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
  productData,
  reviewData,
  editions,
  cannonical_url,
  og_image,
  noCrawl,
  locale,
}) {
  const { storeLocale } = useShopStoreIncontext((state) => {
    return {
      storeLocale: state.storeLocale,
    }
  })
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: 'https://july.com',
      name: 'July',
      alternateName: 'July Luggage',
    },
  ]
  if (productData !== undefined) {
    const product = {
      '@context': 'http://schema.org/',
      '@type': 'Product',
      name: productData.name || '',
      image: productData.imageSrc || '',
      description: productData.description || '',
      sku: productData.sku || '',
      mpn: productData.sku || '',
      brand: {
        '@type': 'Thing',
        name: 'July',
      },
      offers: {
        '@type': 'Offer',
        priceSpecification: {
          '@type': 'PriceSpecification',
          price: productData.price || 0,
          ...(productData.priceArray !== undefined
            ? {
                minPrice: productData.priceArray[0],
                maxPrice: productData.priceArray[1],
              }
            : {}),
          priceCurrency: 'AUD',
        },
        priceCurrency: 'AUD',
        price: parseInt((`${productData.price}`?.match(/\d+/g) || []).join('')) || 0,
        availability: 'http://schema.org/InStock',
        seller: {
          '@type': 'Organization',
          name: 'July',
        },
        priceValidUntil: '2030-12-31',
        url: `https://july.com/au${productData.handle || ''}`,
      },
      review: {
        '@type': 'Review',
        author: {
          '@type': 'Person',
          name: 'Anna K.',
        },
        datePublished: '2019-03-11',
        reviewRating: {
          '@type': 'Rating',
          ratingValue: '5',
          bestRating: '5',
          worstRating: '1',
        },
        reviewBody:
          'A spunky looking piece of carry on with the personality and intelligence (in design) to match. July is the  perfect travelling companion. As a wheelchair user, it’s so easy to manoeuvre alongside my electric chair.',
      },
    }
    if (reviewData !== undefined) {
      product['aggregateRating'] = {
        '@type': 'AggregateRating',
        ratingValue: reviewData.value || '4.9',
        ratingCount: reviewData.count || '121',
      }
    }
    schemaOrgJSONLD.push(product)
  }

  const metaDescription = description || 'July Travel'
  const okendoLookup = {
    'en-GB': '824829cb-61b5-466b-b9bb-55fa5c60434d',
    'en-AU': 'c6473208-10f3-45b1-9e78-3e099c569d70',
    'en-NZ': '0b7ecd86-8dbd-4328-ad90-50bea1527287',
    'en-CA': '42cf0de9-62b2-4da3-a612-d76d144ff639',
    'en': 'dfc2b3db-7314-4480-9d7b-6f50b9721bfc',
  }

  const localeLookup = {
    'en-GB': 'uk',
    'en-AU': 'au',
    'en-NZ': 'nz',
    'en': 'us',
    'en-CA': 'ca',
  }
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={`${title}`}
      titleTemplate={`%s | July`}
      defer={false}
    >
      {noCrawl === true && <meta name="robots" content={'noindex'} />}
      {keywords && <meta name="keywords" content={keywords} />}

      <meta name="description" content={metaDescription} />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:type" content={'website'} />
      <meta property="og:title" content={`${title} | July`} />
      <meta property="og:description" content={metaDescription} />
      <meta
        property="og:image"
        content={og_image === undefined ? (editions ? banner2 : banner) : `${process.env.GATSBY_WEBSITE_URL}${og_image}`}
      />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:site" content={'july'} />
      <meta name="twitter:card" content={'summary'} />
      <meta name="twitter:title" content={`${title} | July`} />
      <meta name="twitter:description" content={metaDescription} />
      <meta
        name="twitter:image"
        content={og_image === undefined ? (editions ? banner2 : banner) : `${process.env.GATSBY_WEBSITE_URL}${og_image}`}
      />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicons/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicons/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicons/favicon-16x16.png"
      />
      <link rel="manifest" href="/favicons/site.webmanifest" />

      {cannonical_url && [
        <link
          rel="canonical"
          href={`https://july.com/${localeLookup[locale]}${cannonical_url}`}
          key={'canonical'}
        />,
        <link
          rel="alternate"
          href={`https://july.com/au${cannonical_url}`}
          hreflang="en-au"
          key={'en-au'}
        />,
        <link
          rel="alternate"
          href={`https://july.com/ca${cannonical_url}`}
          hreflang="en-ca"
          key={'en-ca'}
        />,
        <link
          rel="alternate"
          href={`https://july.com/nz${cannonical_url}`}
          hreflang="en-nz"
          key={'en-nz'}
        />,
        <link
          rel="alternate"
          href={`https://july.com/us${cannonical_url}`}
          hreflang="en-us"
          key={'en-us'}
        />,
        <link
          rel="alternate"
          href={`https://july.com/uk${cannonical_url}`}
          hreflang="en-gb"
          key={'en-gb'}
        />,
        <link
          rel="alternate"
          href={`https://july.com/us${cannonical_url}`}
          hreflang="x-default"
          key={'x-default'}
        />,
      ]}
      <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
      <meta name="oke:subscriber_id" content={okendoLookup[storeLocale]} />
      <script id="okeReferralSettings" type="application/json">{`{"subscriberId":"${okendoLookup[storeLocale]}"}`}</script>
    </Helmet>
  )
}

export default SEO
