const auLookUp = {}
const usLookUp = { 
  25: [
    'SLG-TAG10-BLA',
    'SLG-TAG30-BLA',
    'SLG-WLT01-BLA-S',
    'SLG-WLT02-OYS-S',
    'SLG-WLT10-BLA-L',
    'SLG-KEY12-RED',
    'SLG-KEY22-RED',
    'SLG-KEY32-RED',
    'SLG-LAPTOP03-RED',
    'SLG-TAG52-RED',
    'SLG-TAG62-RED',
    'SLG-WLT14-RED-L',
    'SLG-CARD01-BLA',
    'SLG-CARD02-OYS',
    'SLG-KEY10-BLA',
    'SLG-KEY11-OYS',
    'SLG-KEY20-BLA',
    'SLG-KEY21-OYS',
    'SLG-KEY30-BLA',
    'SLG-KEY31-OYS',
    'SLG-LAPTOP01-BLA',
    'SLG-LAPTOP02-OYS',
    'SLG-PASS01-BLA',
    'SLG-PASS02-OYS',
    'SLG-TAG20-BLA',
    'SLG-TAG21-OYS',
    'SLG-TAG40-BLA',
    'SLG-TAG50-BLA',
    'SLG-TAG51-OYS',
    'SLG-TAG60-BLA',
    'SLG-TAG61-OYS'
  ],
  20: [
    'TEC01-BLA',
    'TEC02-RED',
    'EVE-CRES-81-NAT',
    'EVE-CRES-82-BLA',
    'EVETOT-MINI61-NAT',
    'EVETOT-MINI62-BLA',
    'EVE-BELT-93-BRO',
    'EVE-BELT-94-YEL',
    'EVE-CRES-83-BRO',
    'EVE-CRES-84-YEL',
    'EVETOT2-LAR33-LBLU',
    'EVETOT2-SML43-RED',
    'EVETOT2-SML44-LBLU',
    'EVETOT-LTD-SML46-TAN',
    'TEC04-YEL',
    'TPC01-BLA-BLU',
    'TPC02-BLA-RED',
    'TPC03-BLA-YEL',
    'TPC04-BLA-GRY',
    'EVE-BELT-91-NAT',
    'EVE-BELT-92-BLA',
    'EVETOT2-LAR29-YEL',
    'EVETOT2-SML39-MOS',
    'EVETOT2-SML40-YEL',
    'EVETOT2-SML42-GRY',
    'EVETOT-LAR27-COP',
    'EVETOT-MINI63-YEL',
    'EVETOT-MINI64-BRO',
    'EVETOT-MINI65-GRY'
  ],
  15: [
    'JETTE-CRSBDY09-BLA',
    'JETTE-CRSBDY10-BRO',
    'JETTE-CRSBDY11-BONE',
    'JETTE-CRSBDY12-GRN',
    'JETTE-TOT-LRG03-BONE',
    'JETTE-TOT-SML07-BONE',
    'CHK17-RED',
    'LGT-CO17-DBRO',
    'LGT-CO1-NAV',
    'LGT-CO4-SAN',
    'LGT-CO7-CLA',
    'LGT-CO9-SHA',
    'LGT-PLS62-CHA',
    'LGT-PLS63-FOR',
    'LGT-PLS64-SAN',
    'LGT-PLS65-CLA',
    'LGT-PLS66-SHA',
    'LGT-PLS67-OLI',
    'LGT-PLS68-CGRN',
    'LGT-PLS70-DBRO',
    'PLS06-MON',
    'PLS13-YEL',
    'PLS17-RED',
    'PRO-COPB04-MON',
    'PRO-COPB07-CLA',
    'TRK-CHK01-SAN',
    'TRK-CHK03-GRE',
    'TRK-CHK04-RED',
    'TRK-CHK06-CBLU',
    'TRK-CHK09-LGRY',
    'TRK-COPB01-SAN',
    'TRK-COPB02-GRY',
    'TRK-COPB03-GRE',
    'TRK-COPB04-RED',
    'TRK-COPB06-CBLU',
    'TRK-COPB09-LGRY',
    'TRK-PLS21-SAN',
    'TRK-PLS23-GRE',
    'TRK-PLS24-RED',
    'TRK-PRO-COPB01-SAN',
    'TRK-PRO-COPB03-GRE',
  ],
  10: [
    'CHK03-FOR',
    'CHK04-NUD',
    'CHK09-SHW',
    'CHK11-OLI',
    'PLS04-NUD',
    'PLS07-CLY',
    'PLS09-SHW',
    'PLS11-OLI',
  ],
}
const nzLookUp = {}
const ukLookUp = {}

const skusDiscounts = (locale) => {
  return Object.entries(locale).reduce((skuData, [amount, SKUs]) => {
    SKUs.forEach(SKU => {
      skuData[SKU] = { type: 'percentage', amount: parseInt(amount) };
    });
    return skuData;
  }, {});
};

const AU = skusDiscounts(auLookUp);
const US = skusDiscounts(usLookUp);
const NZ = skusDiscounts(nzLookUp);
const UK = skusDiscounts(ukLookUp);

export default { 'en-AU': {}, en: US, 'en-NZ': {}, 'en-GB': {} }