import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import React from 'react'

import { useShopStoreIncontext } from '../../context/shopContext'

// const locales = require('../../constants/locales')

const StyledLink = styled.a`
  cursor: pointer;
  position: relative;
  ${(props) => (props.style ? props.styles : '')}
  text-decoration: none;
`

const StyledSpan = styled.span`
  ${(props) => (props.style ? props.styles : '')}
`

const Link = (props) => {
  const {
    to,
    children,
    className,
    handleClicked,
    noLink,
    styles,
    handleBeforeClicked,
  } = props
  let storeLocale = useShopStoreIncontext((state) => state.storeLocale)
  const languageLookup = {
    en: 'us',
    'en-AU': 'au',
    'en-NZ': 'nz',
    'en-GB': 'uk',
    'en-CA': 'ca',
  }
  const language = languageLookup[storeLocale]
  let path = to
  const isExternalLink = to?.startsWith('https://') || false

  const oldFAQs = [
    '/faqs/#return-policy', '/faqs/#shipping-and-delivery', '/faqs/#product-warranties'
  ]

  const includeOldFAQ = oldFAQs.includes(to)
  const shippingLinks = {
    au: 'https://help-au.july.com/en-GB/how-much-does-shipping-cost-432284',
    us: 'https://help-us.july.com/en-US/when-will-i-receive-my-order-344031',
    nz: 'https://help-nz.july.com/en-GB/when-will-i-receive-my-order-344096',
    uk: 'https://help-uk.july.com/en-GB/when-will-i-receive-my-order-343969',
  }

  const returnsLinks = {
    au: 'https://help-au.july.com/en-GB/articles/returns-&-exchanges-96944',
    us: 'https://help-us.july.com/en-US/articles/returns-&-exchanges-98106',
    nz: 'https://help-nz.july.com/en-GB/articles/returns-&-exchanges-98123',
    uk: 'https://help-uk.july.com/en-GB/articles/returns-&-exchanges-98089',
  }

  const warrantyLinks = {
    au: 'https://help-au.july.com/en-GB/articles/product-warranties-68840',
    us: 'https://help-us.july.com/en-US/articles/product-warranties-98099',
    nz: 'https://help-nz.july.com/en-GB/articles/product-warranties-98116',
    uk: 'https://help-uk.july.com/en-GB/articles/product-warranties-98085',
  }

  const defaultFAQ = {
    au: 'https://help-au.july.com',
    us: 'https://help-us.july.com',
    nz: 'https://help-nz.july.com',
    uk: 'https://help-uk.july.com'
  }

  let localizedPath = (`/${language}${path && (path.charAt(0) === '/' ? '' : '/')
    }${path}${path &&
    (path.slice(-1) !== '/' &&
      path.indexOf('?') === -1 &&
      path.indexOf('#') === -1
      ? '/'
      : '')
    }`).replace('//', '/')
  if (isExternalLink) {
    localizedPath = to
  }
  if (includeOldFAQ) {
    if (to.includes('#return-policy')) {
      localizedPath = returnsLinks[language]
    } else if (to.includes('#shipping-and-delivery')) {
      localizedPath = shippingLinks[language]
    } else if (to.includes('#product-warranties')) {
      localizedPath = warrantyLinks[language]
    } else {
      localizedPath = defaultFAQ = language
    }
  }

  const handleClick = async (e) => {
    if (e.type === 'click') {
      e.preventDefault()
      if (handleBeforeClicked) {
        handleBeforeClicked()
      }
      if (handleClicked) {
        handleClicked(e)
      }
      if (to && !noLink) {
        if (e.ctrlKey || e.metaKey || isExternalLink || includeOldFAQ) {
          window.open(localizedPath, '_blank')
        } else {
          navigate(localizedPath)
        }
      }
    }
  }
  if (noLink || to === undefined) {
    return (
      <StyledSpan
        {...props}
        style={styles}
        className={className}
        onClick={handleClick}
      >
        {children}
      </StyledSpan>
    )
  }
  return (
    <StyledLink
      {...props}
      href={`${localizedPath}`}
      style={styles}
      className={className}
      onClick={handleClick}
    >
      {children}
    </StyledLink>
  )
}
export default Link
