import styled from '@emotion/styled'
import React from 'react'

const TickerHeading = ({ title, color, data, textColor }) => {
  const firstWord = title?.split(' ')[0]
  const restOfTitle = title
    ?.split(' ')
    .filter((val, key) => key > 0)
    .join(' ')

  return (
    <>
      {(!firstWord && data?.Images.length > 0) ? (
        <div
          style={{
            position: 'relative',
          }}
        >
          <div id={`banner-${title?.toLowerCase()?.replace(/\s+/g, '-')}`} style={{ position: 'absolute', top: -160, height: 1 }} />
          <div className="tickerAnimatePet" style={{ color: color }}>
            <div className="ticker_list">
              <div style={{ display: 'flex' }}>
                {data?.Images.map((image, index) => (
                  <img src={image?.svg?.url} style={{ width: 140 }} key={index} />
                ))}
                {data?.Images.map((image, index) => (
                  <img src={image?.svg?.url} style={{ width: 140 }} key={index} />
                ))}
                {data?.Images.map((image, index) => (
                  <img src={image?.svg?.url} style={{ width: 140 }} key={index} />
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            position: 'relative',
          }}
        >
          <div id={`banner-${title}`} style={{ position: 'absolute', top: -160, height: 1 }} />
          <div className="ticker animate" style={{ margin: 0, color: color }}>
            <div className="ticker_list">
              <h1 className="ticker_item" style={{ color: textColor }}>
                <span
                  style={{
                    fontFamily: 'PPEditorialNew',
                    fontSize: '0.5em',
                    fontWeight: '400',
                    fontStyle: 'italic',
                  }}
                >
                  {' '}
                  {firstWord}{' '}
                </span>
                <span
                  style={{
                    fontFamily: 'PPEditorialNew',
                    fontSize: '0.5em',
                    fontWeight: '400',
                    fontStyle: 'italic',
                  }}
                >
                  {restOfTitle}
                </span>
              </h1>
            </div>
            <div className="ticker_list">
              <h1 className="ticker_item" style={{ color: textColor }}>
                <span
                  style={{
                    fontFamily: 'PPEditorialNew',
                    fontSize: '0.5em',
                    fontWeight: '400',
                    fontStyle: 'italic',
                  }}
                >
                  {firstWord}{' '}
                </span>
                <span
                  style={{
                    fontFamily: 'PPEditorialNew',
                    fontSize: '0.5em',
                    fontWeight: '400',
                    fontStyle: 'italic',
                  }}
                >
                  {restOfTitle}
                </span>
              </h1>
            </div>
            <div className="ticker_list">
              <h1 className="ticker_item" style={{ color: textColor }}>
                <span
                  style={{
                    fontFamily: 'PPEditorialNew',
                    fontSize: '0.5em',
                    fontWeight: '400',
                    fontStyle: 'italic',
                  }}
                >
                  {firstWord}{' '}
                </span>
                <span
                  style={{
                    fontFamily: 'PPEditorialNew',
                    fontSize: '0.5em',
                    fontWeight: '400',
                    fontStyle: 'italic',
                  }}
                >
                  {restOfTitle}
                </span>
              </h1>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const DetailsHeader = styled.h2`
  font-family: Objectivity;
  margin: 0px 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  text-align: center;
  color: ${(props) => props.textColor ? `${props.textColor}` : 'black;'};
  padding: 4px 0 32px 0;
  font-size: ${(props) => props.fontSize === 'small' ? '14px;'
    : props.fontSize === 'medium' ? ' 18px;'
      : props.fontSize === 'large' ? '26px;'
        : '15px;'};
  font-weight: ${(props) => props.fontWeight ? `${props.fontWeight}` : '400;'};
`

const TickerPanel = ({ data }) => {
  return (
    <div
      style={{
        overflow: 'hidden',
        backgroundColor: data.backgroundColour,
      }}
    >
      <TickerHeading title={data.tickerText} color={data.backgroundColour} data={data} textColor={data.textColor} />
      {
        data?.extendedSubCopy ? (
          <DetailsHeader fontSize={data.subCopyFontSize} fontWeight={data.subCopyFontWeight} textColor={data.textColor} dangerouslySetInnerHTML={{ __html: data.extendedSubCopy && data.extendedSubCopy }} />
        ) : (
          <>
            {data?.subCopy && (
              <DetailsHeader fontSize={data.subCopyFontSize} fontWeight={data.subCopyFontWeight} textColor={data.textColor}>{data.subCopy}</DetailsHeader>
            )}
          </>
        )
      }
    </div >
  )
}

export default TickerPanel
