import { useEffect, useRef, useState } from 'react'

const useIsInView = (additionalRef) => {
  const [isInView, setIsInView] = useState(true)
  const inViewRef = useRef()

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        let selectIsInView = false
        let footerIsInView = false

        entries.forEach((entry) => {
          if (entry.target === inViewRef.current) {
            selectIsInView = entry.isIntersecting
          } else if (entry.target === additionalRef?.current) {
            footerIsInView = entry.isIntersecting
          }
        })

        setIsInView(!(!selectIsInView && !footerIsInView))
      },
      {
        root: null, // viewport
        rootMargin: '0px',
        threshold: 0.1, // adjust as needed
      }
    )

    if (inViewRef.current) {
      observer.observe(inViewRef.current)
    }

    if (additionalRef?.current) {
      observer.observe(additionalRef.current)
    }

    // Clean up
    return () => {
      if (inViewRef.current) {
        observer.unobserve(inViewRef.current)
      }

      if (additionalRef?.current) {
        observer.unobserve(additionalRef.current)
      }
    }
  }, [additionalRef]) // Empty array ensures effect is only run on mount and unmount

  return {
    inViewRef,
    isInView,
  }
}

export default useIsInView
