import React, { useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'

const ArrowContainer = styled.div`
  position: absolute;
  &:hover {
    transition: filter 0.3s ease;
    filter: opacity(0.6) !important;
  }

  &:active {
    transition: filter 0.3s ease;
    filter: opacity(0.1) !important;
  }

  svg {
    width: 12px;
    height: 12px;
    path {
      transition: fill 0.3s ease;
    }
  }
`

export const EmBlaCarouselArrowButtons = (emblaApi) => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollPrev()
  }, [emblaApi])

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollNext()
  }, [emblaApi])

  const onSelect = useCallback((emblaApi) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev())
    setNextBtnDisabled(!emblaApi.canScrollNext())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect).on('select', onSelect)
  }, [emblaApi, onSelect])

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  }
}

export const PrevButton = (props) => {
  const { children, className, ...restProps } = props

  let color = props?.buttonColor === 'light' ? 'white' : 'black'
  if (props?.color) {
    color = props.color
  }

  return (
    <ArrowContainer className={`${className}`} {...restProps}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
        <path
          d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
          fill={color}
        />
      </svg>
    </ArrowContainer>
  )
}

export const NextButton = (props) => {
  const { children, className, ...restProps } = props
  let color = props?.buttonColor === 'light' ? 'white' : 'black'
  if (props?.color) {
    color = props.color
  }

  return (
    <ArrowContainer className={`${className}`} {...restProps}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
        <path
          d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
          fill={color}
        />
      </svg>
    </ArrowContainer>
  )
}
