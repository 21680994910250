export const stockLookup = {
  'en-AU': 'https://july.com/stock/AU.json',
  'en-GB': 'https://july.com/stock/UK.json',
  'en-NZ': 'https://july.com/stock/AU.json',
  en: 'https://july.com/stock/US.json',
  'en-CA': 'https://july.com/stock/US.json',
}

export const currencyCodeWithPrefixLookUp = {
  'en-AU': 'AUD$',
  'en-NZ': 'NZD$',
  'en-GB': 'GBP£',
  en: 'USD$',
  'en-CA': 'CAD$',
}

export const currencyOnly = {
  'en-AU': '$',
  'en-NZ': '$',
  'en-GB': '£',
  en: '$',
}

export const currencyCodeLookup = {
  'en-AU': 'AUD',
  'en-NZ': 'NZD',
  'en-GB': 'GBP',
  en: 'USD',
  'en-CA': 'CAD',
}

export const analyticsShopData = {
  'en-AU': {
    shopId: 'gid://shopify/Shop/4716396613',
    currency: 'AUD',
    acceptedLanguage: 'en',
  },
  'en-NZ': {
    shopId: 'gid://shopify/Shop/71384990008',
    currency: 'NZD',
    acceptedLanguage: 'en',
  },
  en: {
    shopId: 'gid://shopify/Shop/27817082983',
    currency: 'USD',
    acceptedLanguage: 'en',
  },
  'en-CA': {
    shopId: 'gid://shopify/Shop/58963984471',
    currency: 'USD',
    acceptedLanguage: 'en',
  },
  'en-GB': {
    shopId: 'gid://shopify/Shop/58215661756',
    currency: 'GBP',
    acceptedLanguage: 'en',
  },
}

export const localeMap = {
  us: 'en',
  au: 'en-AU',
  ca: 'en-CA',
  uk: 'en-GB',
  nz: 'en-NZ',
}

export const currencyLookup = {
  'en-AU': 'AU$',
  'en-NZ': 'NZ$',
  'en-GB': 'GB£',
  'en': 'US$',
  'en-CA': 'CA$',
}
